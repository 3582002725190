import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DetailBlock } from '../../Components/DetailBlock/DetailBlock';

interface IRealTimeAvailabilityProps {

}

export const RealTimeAvailability: StatelessComponent<IRealTimeAvailabilityProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered header'>
                            <h2>Real Time Availability</h2>
                        </Col>
                    </Row>
                    <DetailBlock
                        detailImage="/images/screenshots/RealTime_Pricing.png"
                        detailImageAlt="Your Distributor Pricing"
                        detailTitle="Your Distributor Pricing"
                        detailText="View your custom pricing from all of your distributors side by side, so that you can make informed buying decisions."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/RealTime_Availability.png"
                        detailImageAlt="Real-Time Availability"
                        detailTitle="Real-Time Availability"
                        detailText="Filter your catalogs by products that are in stock and see at a glance the availability of all your vendors. Click into the product to list out availability by warehouse and ETA for out of stock items."
                        imageRight
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/RealTime_Distributors.png"
                        detailImageAlt="Supporting a Wide Range of Distributors"
                        detailTitle="Supporting a Wide Range of Distributors"
                        detailText="Adaptive Catalog supports many of the most popular IT distributors out of the box, and we are adding more all the time. You can also import pricing and availability from any distributor offering a price file."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/RealTime_Insights.png"
                        detailImageAlt="Insights and Notifications"
                        detailTitle="Insights and Notifications"
                        detailText="Gain insights on your products and view pricing and availability over time. Keep informed of product availability and get notifications when out of stock products become available or when prices change on products you purchase frequently."
                        imageRight
                    />
                    <Row className="centered">
                        <Col lg={12} md={12} sm={12} className='centered footer'>
                            <a href="/" className="btn btn-light">Home</a>&nbsp;&nbsp;
                            <a href="/Features/ECommerce" className="btn btn-light">Continue to E-Commerce</a>
                        </Col>   
                    </Row>

                </Container>
            </Container>
        </>
    );
}