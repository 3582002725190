import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Bio } from '../Components/Bio/Bio';
import { ContactCard } from '../Components/ContactCard/ContactCard';

interface IContactCardProps {

}

export const syu: StatelessComponent<IContactCardProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    
                    <ContactCard
                        bioPic="/images/stephenYuBioPic.jpg"
                        bioPicAlt="Stephen Yu"
                        name="Stephen Yu"
                        jobTitle="Chief Executive Officer"
                        email="syu@adaptivecatalog.com"
                        phoneNumber='206.853.4303'
                        bioText={''}   
                        vcard="/files/StephenYu.vcf"                 />

                </Container>
            </Container>
        </>
    );
}