import React, { StatelessComponent } from 'react';
import { } from 'reactstrap';

interface IFeatureProps {
    icon: string;
    iconAlt?: string;
    title: string;
    description: string;
    videoWebm?: string;
    videoMp4?: string;
    rightAlign?: boolean;
}

export const Feature: StatelessComponent<IFeatureProps> = (props) => {
    return (
        <div className="feature">
            <div className={`halfSection ${props.rightAlign ? ' float-right' : ''}`}>
                <img className="smallIcon" src={props.icon} alt={props.iconAlt} />
                <h3 className="titleLink float-left">{props.title}</h3>
                <p className="float-left">{props.description}</p>
            </div>
            <div className={`halfSection ${props.rightAlign ? ' float-right' : ''}`}>
                <video width="100%" preload="yes" playsInline autoPlay loop>
                    <source src={props.videoWebm} type="video/webm"/>
                    <source src={props.videoMp4} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className='clearFix'/>
        </div>
    )
}