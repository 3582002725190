import React, { StatelessComponent } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import { PriceComparison } from '../Components/PriceComparison/PriceComparison';


interface IPricingProps {

}

export const Pricing: StatelessComponent<IPricingProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered priceDisplay'>
                            <h2>Starting at <span className="bigGreenText">$49</span>/month</h2>
                            <a href="/#contactForm" className="btn btn-outline-success">Schedule a demo today</a>
                        </Col>
                    </Row>
                    <Table className="priceTable">
                        <thead>
                            <PriceComparison
                                featureName=""
                                SaaSIncluded={<><span className="bigGreenText">SaaS</span><br /><span>$49/month (per user)</span></>}
                                EnterpriseIncluded={<><span className="bigGreenText">Enterprise</span><br /><span>Contact Us</span></>}
                                Header={true}
                            />
                        </thead>
                        <tbody>
                            <PriceComparison
                                featureName="Rich Product Data"
                                SaaSIncluded="✓"
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Advanced Search"
                                SaaSIncluded="✓"
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Real Time Vendor Data"
                                SaaSIncluded="✓"
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Rich Product Data"
                                SaaSIncluded="✓"
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Price Filtering"
                                SaaSIncluded="✓"
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Manufacturer Recommendations"
                                SaaSIncluded="✓"
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="RFP Analyzer"
                                SaaSIncluded={<span className="addnlCost">$10/month</span>}
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Custom Catalogs"
                                SaaSIncluded={<span className='notIncluded'>✗</span>}
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="API Access"
                                SaaSIncluded={<span className='notIncluded'>✗</span>}
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Teams"
                                SaaSIncluded={<span className='notIncluded'>✗</span>}
                                EnterpriseIncluded="✓"
                            />
                            <PriceComparison
                                featureName="Enterprise SSO"
                                SaaSIncluded={<span className='notIncluded'>✗</span>}
                                EnterpriseIncluded="✓"
                            />
                        </tbody>
                    </Table>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered '>
                            <p>A comprehensive list of features is available in the PDF linked below.</p>
                            <a href="/images/FeatureComparisonChart.pdf" target="_blank" className="btn btn-light">View PDF</a>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}