import axios from 'axios';

export interface IContactFormRequest {
    name?: string;
    email?: string;
    message?: string;
    token?: string;
}

const API_URL = 'https://adaptivecatalog-web-api.azurewebsites.net/api/contactform?code=mbwTrI5lZIN0dDaej55ZKW8NMfgSPd/BA4bkLzKzVgRuhS9bTaJaIg==';

export class ContactFormDAL {
    public async Run(request: IContactFormRequest) {
        const result = await axios
            .post(API_URL, request);
        if (result.status === 200) {
            return true;
        } else {
            return false;
        }
    }
}