import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';


interface IMSPLandingPageProps {

}

export const MSPLandingPage: React.FC<IMSPLandingPageProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={4} md={4} sm={12} className='centered comparison'>
                            <div className="comparisonData">
                                <div className="comparisonHeader"><h2>Basic</h2></div>
                                <h2><span className="bigGreenText">FREE</span></h2>
                                <h5>Includes:</h5>
                                <ul>
                                    <li>Intelligent Keyword Search</li>
                                    <li>Rich Product Data</li>
                                    <li>General Product Availability</li>
                                    <li>Product Recommendations</li>
                                    <li>Kaseya BMS Integration</li>
                                    <li>Purchase Products in Adaptive Catalog</li>
                                </ul>
                                <div className="comparisonFooter">
                                    <div>
                                        <a href="https://adaptivecatalog.com/signup/basic" className="btn btn-success">Sign Up Today!</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} className='centered comparison'>
                            <div className="comparisonData">
                                <div className="comparisonHeader"><h2>Standard</h2></div>
                                <h2><span className="bigGreenText">$49</span>/month</h2>
                                <h5>All Basic features, and:</h5>
                                <ul>
                                    <li>Your Real-time Pricing and Availability from Distributors</li>
                                    <li>Custom Product Sources</li>
                                    <li>ConnectWise PSA and CPQ Integration</li>
                                    <li>Autotask Integration</li>
                                    <li>Company Favorites</li>
                                    <li>Product Editing</li>
                                </ul>
                                <div className="comparisonFooter">
                                    <div>
                                        <a href="/#contactForm" className="btn btn-success">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} className='centered comparison'>
                            <div className="comparisonData">
                                <div className="comparisonHeader"><h2>Enterprise</h2></div>
                                <h4>Contact us for pricing</h4>
                                <h5>All Standard Features, and:</h5>
                                <ul>
                                    <li>Document Analyzer</li>
                                    <li>Extension Authoring - Premium Support</li>
                                    <li>Salesforce Integration</li>
                                    <li>NetSuite Integration</li>
                                    <li>Dynamics Integration</li>
                                    <li>Custom Items and Catalogs</li>
                                    <li>Custom AI Product Recommendations</li>
                                    <li>Enterprise SSO</li>
                                    <li>API Access</li>

                                </ul>
                                <div className="comparisonFooter">
                                    <div>
                                        <a href="/#contactForm" className="btn btn-success">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </Container>
        </>
    );
}