import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DetailBlock } from '../../Components/DetailBlock/DetailBlock';

interface ISearchCustomizationProps {

}

export const SearchCustomization: StatelessComponent<ISearchCustomizationProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered header'>
                            <h2>Search Customization</h2>
                        </Col>
                    </Row>
                    <DetailBlock
                        detailImage="/images/screenshots/Search_Keyword.png"
                        detailImageAlt="Intelligent Keyword Search"
                        detailTitle="Intelligent Keyword Search"
                        detailText="Adaptive Catalog intelligently searches all of the fields that matter most to help find exactly what you are looking for with one search box. Get suggestions as you type for manufacturers, products, and categories."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/Search_Filtering.png"
                        detailImageAlt="Advanced Attribute Filtering"
                        detailTitle="Advanced Attribute Filtering"
                        detailText="Find products that meet your exact specifications with Adaptive Catalog’s industry leading attribute search. Specify what features are must haves, nice to haves, and deal breakers to match even the most complex customer requirements."
                        imageRight
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/Search_Price.png"
                        detailImageAlt="Search Price and Availability"
                        detailTitle="Search Price and Availability"
                        detailText="Filter your search results to only products that are in stock with your distributors. Find products that are within your customer’s budget without sacrificing your margins. See which warehouses nearby have the products on hand to deliver quickly on commitments."
                    />
                    <Row className="centered">
                        <Col lg={12} md={12} sm={12} className='centered footer'>
                            <a href="/" className="btn btn-light">Home</a>&nbsp;&nbsp;
                            <a href="/Features/RealTimeAvailability" className="btn btn-light">Continue to Real Time Availability</a>
                        </Col>   
                    </Row>

                </Container>
            </Container>
        </>
    );
}