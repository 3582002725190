import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DetailBlock } from '../../Components/DetailBlock/DetailBlock';

interface IECommerceProps {

}

export const ECommerce: StatelessComponent<IECommerceProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered header'>
                            <h2>E-Commerce</h2>
                        </Col>
                    </Row>
                    <DetailBlock
                        detailImage="/images/screenshots/ECommerce_Management.png"
                        detailImageAlt="Centralized Management"
                        detailTitle="Centralized Management"
                        detailText="Adaptive Catalog provides a centralized location to manage all of your E-Commerce products. Descriptions, attributes, and images are all synchronized between your product catalog and your E-Commerce site."
                    />
                    <DetailBlock
                        detailImage="/images/screenshots/ECommerce_Pricing.png"
                        detailImageAlt="Intelligent Pricing"
                        detailTitle="Intelligent Pricing"
                        detailText="Adaptive Catalog can set your E-Commerce product margins based on the lowest, highest, or average in stock vendor cost, the MSRP of the product, or a manually set price."
                        imageRight
                    />
                    <Row className="centered">
                        <Col lg={12} md={12} sm={12} className='centered footer'>
                            <a href="/" className="btn btn-light">Home</a>&nbsp;&nbsp;
                            <a href="/Features/BidAnalysis" className="btn btn-light">Continue to Bid Analysis</a>
                        </Col>   
                    </Row>

                </Container>
            </Container>
        </>
    );
}