import React, { Component } from 'react';
import createHistory from 'history/createBrowserHistory'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { HomePage } from './Pages/HomePage';
import { ChannelPartnersExpo2020 } from './Pages/ChannelPartnersExpo2020';
import { ExecutiveTeam } from './Pages/ExecutiveTeam';
import { Pricing } from './Pages/Pricing';
import { Catalogs } from './Pages/WalkThroughDetails/Catalogs';
import { SearchCustomization } from './Pages/WalkThroughDetails/SearchCustomization';
import { RealTimeAvailability } from './Pages/WalkThroughDetails/RealTimeAvailability';
import { ECommerce } from './Pages/WalkThroughDetails/ECommerce';
import { BidAnalysis } from './Pages/WalkThroughDetails/BidAnalysis';
import { CRMERPIntegrations } from './Pages/WalkThroughDetails/CRMERPIntegrations';
import { Videos } from './Pages/Videos';
import { ChannelPartnersExpo2021 } from './Pages/ChannelPartnersExpo2021';
import { ITNConnect2021 } from './Pages/ITNConnect2021';
import { Brochure2021 } from './Pages/Landing/Brochure2021';
import { ConnectIT2022 } from './Pages/ConnectIT2022';
import { ssteck } from './Pages/ssteck';
import { syu } from './Pages/syu';
import { ITNConnect2022 } from './Pages/ITNConnect2022';
import { AboutUs } from './Pages/AboutUs';
import { KaseyaConnect23 } from './Pages/KaseyaConnect23';
import { AsciiEdge23 } from './Pages/AsciiEdge23';
import { MSPLandingPage } from './Pages/MSPLandingPage';
import { ITNSecure2023 } from './Pages/ITNSecure2023';
import { Pax8Beyond23 } from './Pages/Pax8Beyond23';
import { ChannelCon23 } from './Pages/ChannelCon23';
import { ITNConnect2023 } from './Pages/ITNConnect2023';

const history = createHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
});

export default class ACRouter extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/conferences/ChannelCon23' component={ChannelCon23} />
                    <Route exact path='/conferences/channelPartnersExpo2020' component={ChannelPartnersExpo2020} />
                    <Route exact path='/conferences/channelPartnersExpo2021' component={ChannelPartnersExpo2021} />
                    <Route exact path='/conferences/ConnectIT2022' component={ConnectIT2022} />
                    <Route exact path='/conferences/ITNConnect2021' component={ITNConnect2021} />
                    <Route exact path='/conferences/ITNConnect2022' component={ITNConnect2022} />
                    <Route exact path='/conferences/ITNConnect2023' component={ITNConnect2023} />
                    <Route exact path='/conferences/KaseyaConnect23' component={KaseyaConnect23} />
                    <Route exact path='/conferences/AsciiEdge23' component={AsciiEdge23} />
                    <Route exact path='/conferences/ITNSecure2023' component={ITNSecure2023} />
                    <Route exact path='/conferences/Pax8Beyond23' component={Pax8Beyond23} />
                    <Route exact path='/ExecutiveTeam' component={ExecutiveTeam} />
                    <Route exact path='/AboutUs' component={AboutUs} />
                    <Route exact path='/Pricing' component={Pricing} />
                    <Route exact path='/Videos' component={Videos} />
                    <Route exact path='/Features/Catalogs' component={Catalogs} />
                    <Route exact path='/Features/SearchCustomization' component={SearchCustomization} />
                    <Route exact path='/Features/RealTimeAvailability' component={RealTimeAvailability} />
                    <Route exact path='/Features/ECommerce' component={ECommerce} />
                    <Route exact path='/Features/BidAnalysis' component={BidAnalysis} />
                    <Route exact path='/Features/CRM-ERPIntegrations' component={CRMERPIntegrations} />
                    <Route exact path='/landing/brochure2021' component={Brochure2021} />
                    <Route exact path='/ssteck' component={ssteck} />
                    <Route exact path='/syu' component={syu} />
                    <Route exact path='/signup/MSP' component={MSPLandingPage} />
                    <Route exact path='/calendar/syu/30mindemo' component={() => {
                        window.location.href = 'https://outlook.office365.com/owa/calendar/AdaptiveCatalogDiscussionwStephenYu@adaptivecatalog.com/bookings/s/rtaStYoguECV9AoiU9vhuA2';
                        return null;
                    }} />
                    <Route exact path='/calendar/syu/1hrmeeting' component={() => {
                        window.location.href = 'https://outlook.office365.com/owa/calendar/AdaptiveCatalogDiscussionwStephenYu@adaptivecatalog.com/bookings/s/XBJOknvpaUa8odT6TRgniw2';
                        return null;
                    }} />
                </Switch>
            </BrowserRouter>
        )
    }
}