import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

interface ChannelCon23Props {

}

export const ChannelCon23: React.FC<ChannelCon23Props> = (props) => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: rgb(187, 187, 187); }'}</style>
            </Helmet>
            <Container className='body-content'>

                <Container className="conferenceBody">
                    <Row className="conferenceBGChannelCon23 centered">
                        <Col className="centered mx-auto" lg={4} md={6} sm={12} >
                            <img src="/images/build-the-impossible.png" className="logoStyle" alt='Build The Impossible'/> 
                            <h4>CompTIA ChannelCon 2023</h4>
                            <h4>August 1-3rd in Las Vegas</h4>
                        </Col>
                    </Row>
                    <Row className="whiteBG">
                        <Col lg={6} md={12} sm={12} className='centered mx-auto' >
                            <div className="whiteBox centered">
                                <img src="/images/mainLogo.png" className="logoStyle" alt='Adaptive Catalog'/>
                                <h2>We'll be at booth #615</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="conferenceBGChannelCon23bottom">
                        <Col lg={8} md={12} sm={12} className="centered mx-auto">
                            <div>
                                <p>Come pay us a visit to learn more about how you can:</p>
                                <ul className="left">
                                    <li>Search hardware, software, and services from Ingram Micro, TD Synnex, and Pax8</li>
                                    <li>Integrate with PSA and quoting from Kaseya and CW or generate a word document directly</li>
                                    <li>Discover recommendations and configurations to match your customer requests</li>
                                </ul>
                                <p>We can't wait to see you there!</p>
                                <a href="/#contactForm" className="btn btn-success">Schedule a demo</a>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}