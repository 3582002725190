import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';

interface IAboutUsProps {

}

export const AboutUs: StatelessComponent<IAboutUsProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered'>
                            <h2>About Us</h2>
                        </Col>
                    </Row>
                    <div className='section jumbotron'>
                        <Row>
                            <Col lg={6} md={12} sm={12}>
                            
                                <p>
                                    Sourcing the right hardware, software, and services for technology resellers can be a daunting task. Adaptive Catalog was founded in 2019 by Stephen Yu &amp; Stephen Steck when they saw how difficult it was for managed service providers to respond to customer requests with recommendations that fulfilled the customer need and was compatible with the systems already in place.
                                </p>
                                <p>
                                    We've built the fastest and most accurate search engine for technology resellers to find the parts they need from distributors such as Ingram Micro, TD Synnex, and D&amp;H. We show price and availability across all distributors, and integrate with leading quoting tools and PSA systems such as ConnectWise and Kaseya so that the entire bill of materials flows smoothly through your operational system. With our group purchasing power, we also help smaller resellers get the best pricing from distributors.
                                </p>
                                <p>
                                    Our catalog has pictures, marketing descriptions, and brochures from all the major technology manufacturers, pairing it with vendor part numbers from the distributors. Contact us to see how we can help your sales and procurement team sell more products.
                                </p>
                            
                            </Col>
                            <Col lg={6} md={12} sm={12} className='centered'>
                                <img className="logoStyle" src="/images/Distributors.png" />
                            </Col>
                        </Row>
                    </div>
                    

                </Container>
            </Container>
        </>
    );
}