import React, { StatelessComponent } from 'react';

interface IPriceComparisonProps {
    featureName: string;
    SaaSIncluded: string | React.ReactElement;
    EnterpriseIncluded: string | React.ReactElement;
    Header?: boolean;
}

export const PriceComparison: StatelessComponent<IPriceComparisonProps> = (props) => {
    return props.Header
    ? (
        <tr className="priceHeaderRow">
            <td>
                <h4>{props.featureName}</h4>
            </td>
            <th className="priceColumnHeader">
                {props.SaaSIncluded}
            </th>
            <th className="priceColumnHeader">
                {props.EnterpriseIncluded}
            </th>
        </tr>
    )
    : (
        <tr className="priceRow">
            <td className="priceFeatureColumn">
                <h5>{props.featureName}</h5>
            </td>
            <td>
                {props.SaaSIncluded}
            </td>
            <td >
                {props.EnterpriseIncluded}
            </td>
        </tr>
    )
}