import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ContactForm } from '../Components/ContactForm/ContactForm';

interface IChannelPartnersProps {

}

export const ChannelPartnersExpo2020: StatelessComponent<IChannelPartnersProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h2 className='centered'>We'll be at Channel Partners Expo!</h2>
                            <p id='whiteBox'>Adaptive Catalog is excited to sponsor the Channel Partners Expo 2020, March 10th through 12th.  Find out how your company can save time and win more bids using our versatile product search, curation, and recommendation engine at Booth #214 in the Expo Hall. Schedule a personal one on one demo outside of expo hours or speak to one of our representatives by filling out the form below.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={12} sm={12} className='centered'>
                            <div id='channelPartnersBanner'>
                                <a
                                    target="_blank"
                                    href="https://polo.feathr.co/v1/analytics/crumb?a_id=582208847c1fea1404eef533&cpn_id=5e18d8f083724dfd8430c642&crv_id=5e449fd96f20118251d65840&flvr=ad_click&e_id=5cbf8fdce8143a01a71d0759&t_id=5e18d8f083724dfd8430c645&pcpn_id=5d893974c7ab14000ba698a2&rdr=1&p_id=5e449e8be78b303231a47cec"
                                    rel="noopener noreferrer"
                                >
                                    <img src="/images/channelPartnersExpoPic.png" alt="Channel Partners Conference and Expo"/>
                                </a>
                                
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <h3 className='centered' style={{marginTop:"1em"}}>Schedule a Demo</h3>
                            <ContactForm source='Channel Partners Expo 2020' />
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}