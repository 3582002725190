import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Bio } from '../Components/Bio/Bio';

interface IExecutiveTeamProps {

}

export const ExecutiveTeam: StatelessComponent<IExecutiveTeamProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='centered'>
                            <h2>Executive Team</h2>
                        </Col>
                    </Row>
                    <Bio
                        bioPic="/images/stephenYuBioPic.jpg"
                        bioPicAlt="Stephen Yu"
                        name="Stephen Yu"
                        jobTitle="Co-Founder and CEO"
                        bioText="Stephen Yu has worked for over 20 years in information technology and software engineering. He began his career creating developer tools for AMD and Goldman Sachs and transitioned to developing management software for small business. Stephen graduated with a Bachelor of Science in Electrical Engineering from the University of Texas and an MBA from the University of Washington. Prior to co-founding Adaptive Catalog, Stephen was the co-founder and CTO for Quosal, a configure, price, quoting tool that was acquired by ConnectWise in 2016." 
                    />
                    <Bio
                        bioPic="/images/stephenSteckBioPic.jpg"
                        bioPicAlt="Stephen Steck"
                        name="Stephen Steck"
                        jobTitle="Co-Founder and CTO"
                        bioText="Stephen Steck has over a decade of experience in software development and management, specializing in cloud architecture and search technologies. Stephen studied computer science at Bellevue College and has certifications from Microsoft, Elastic, and the Scrum Alliance. Prior to co-founding Adaptive Catalog, Stephen led the development teams for the Sell product at ConnectWise and worked as a senior engineer on the cloud team." 
                    />
                    {/* <Row>
                        <Col lg={6} md={12} sm={12} className='centered'>
                            <div>
                                <Card>
                                    <CardImg top width="100%" src="/images/stephenYuBioPic.jpg" alt="Stephen Yu" />
                                    <CardBody>
                                        <CardTitle>Stephen Yu</CardTitle>
                                        <CardSubtitle>Co-Founder and CEO</CardSubtitle>
                                        <CardText>Stephen Yu has worked for over 20 years in information technology and software engineering.  He began his career creating developer tools for AMD and Goldman Sachs and transitioned to developing management software for small business.  Stephen graduated with a Bachelor of Science in Electrical Engineering from the University of Texas and and MBA from the University of Washington.  Prior to co-founding Adaptive Catalog, Stephen was the co-founder and CTO for Quosal, a configure, price, quoting tool that was acquired by ConnectWise in 2016.</CardText>
                                    </CardBody>
                                </Card>

                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className='centered'>
                        </Col>
                    </Row>
                    <Row>    
                        <Col lg={6} md={12} sm={12} className='centered'>
                            <div>
                                <Card>
                                    <CardImg top width="100%" src="/images/stephenSteckBioPic.jpg" alt="Stephen Steck" />
                                    <CardBody>
                                        <CardTitle>Stephen Steck</CardTitle>
                                        <CardSubtitle>Co-Founder and CTO</CardSubtitle>
                                        <CardText>Stephen Steck has over a decade of experience in software development and management, specializing in cloud architecture and search technologies. Stephen studied computer science at Bellevue College and has certifications from Microsoft, Elastic, and the Scrum Alliance. Prior to co-founding Adaptive Catalog, Stephen led the development teams for the Sell product at ConnectWise and worked as a senior engineer on the cloud team.</CardText>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className='centered'>
                        </Col>
                    </Row> */}

                </Container>
            </Container>
        </>
    );
}