import React, { StatelessComponent } from 'react';
import CookieConsent from 'react-cookie-consent';

interface ICookieConsentBarProps {

}

export const CookieConsentBar: StatelessComponent<ICookieConsentBarProps> = (props) => {
    return (
        <CookieConsent
            location='bottom'
            buttonText='Accept'
            cookieName='AdaptiveCatalogPrivacyPolicy'
            >
            This site uses cookies to improve user experience. By using our website you consent to all cookies in accordance with our privacy policy.
        </CookieConsent>
    )
}