import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

interface Pax8Beyond23Props {

}

export const Pax8Beyond23: React.FC<Pax8Beyond23Props> = (props) => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: rgb(187, 187, 187); }'}</style>
            </Helmet>
            <Container className='body-content'>

                <Container className="conferenceBody">
                    <Row className="conferenceBGPax8Top centered">
                        <Col className="centered mx-auto" lg={4} md={6} sm={12} >
                            <img src="/images/pax8-logo-white-100.svg" className="smallLogoStyle" alt='Pax8'/>
                            <h4>Pax8 Beyond</h4>
                            <h4>June 11-13th in Denver, CO</h4>
                        </Col>
                    </Row>
                    <Row className="whiteBG">
                        <Col lg={6} md={12} sm={12} className='centered mx-auto' >
                            <div className="whiteBox centered">
                                <img src="/images/mainLogo.png" className="logoStyle" alt='Adaptive Catalog'/>
                                <h2>We'll be at booth #527</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="conferenceBGPax8Beyond">
                        <Col lg={8} md={12} sm={12} className="ITNCDetails centered mx-auto">
                            <div>
                                <p>Come pay us a visit to learn more about:</p>
                                <ul className="left">
                                    <li>Fast, reliable search for hardware, software, and Pax8 cloud marketplace products</li>
                                    <li>All your distributor pricing and availability in one pane of glass</li>
                                    <li>Recommendations and configurations to match your customer requests</li>
                                    <li>Unified quoting solution for  ConnectWise, Kaseya BMS, and Autotask</li>
                                </ul>
                                <p>We can't wait to see you there!</p>
                                <a href="/#contactForm" className="btn btn-success">Schedule a demo</a>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}