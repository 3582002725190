import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Video } from '../Components/Videos/Video';

interface IVideosProps {

}

export const Videos: StatelessComponent<IVideosProps> = (props) => {
    return (
        <>
            <Container className='body-content'>
                
                <Row>
                <Col lg={6} md={6} sm={12}>
                        <Video
                            videoLink="https://www.youtube.com/embed/wVN_b5OvF1I"
                            videoTitle="Adaptive Catalog vs. Legacy Search"
                            videoDescription="See how much time you can save searching for products in Adaptive Catalog."
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Video
                            videoLink="https://www.youtube.com/embed/ip7R6p5dtpY"
                            videoTitle="Adaptive Catalog Overview"
                            videoDescription="Learn more about Adaptive Catalog's mission and main features with this quick overview video."
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Video
                            videoLink="https://www.youtube.com/embed/qX0WTmvf_OI"
                            videoTitle="Real-Time Availability"
                            videoDescription="View your custom pricing from all of your distributors side by side, so that you can make informed buying decisions. Filter your catalogs by products that are in stock and see at a glance the availability of all your vendors. Click into the product to list out availability by warehouse and ETA for out of stock items."
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Video
                            videoLink="https://www.youtube.com/embed/hE0zKd1CSLQ"
                            videoTitle="Product Details"
                            videoDescription="Adaptive Catalog provides rich content for millions of technology products, including marketing information, pictures, sales brochures, and accurate technical specifications."
                        />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Video
                            videoLink="https://www.youtube.com/embed/PiCU4w-4QrY"
                            videoTitle="Attribute Filtering"
                            videoDescription="Find products that meet your exact specifications with Adaptive Catalog’s industry leading attribute search. Specify what features are must haves, nice to haves, and deal breakers to match even the most complex customer requirements."
                        />
                    </Col>
                </Row>
                
            </Container>
        </>
    );
}