import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ContactForm } from '../Components/ContactForm/ContactForm';
import { Feature } from '../Components/Feature/Feature';
import { Integration } from '../Components/Integration/Integration';

interface IHomePageProps {

}

export const HomePage: React.FC<IHomePageProps> = (props) => {
    return (
        <>
            <Container className='body-content'>
                <div className="section intro">
                    <Row>
                        <Col lg={4} md={4} sm={12} >
                        <img className="icon" alt="magnifying glass" src="images/magnifyingGlass.png"/>
                        <h2>Search.</h2>
                        </Col>
                        <Col lg={4} md={4} sm={12} >
                        <img className="icon" alt="grid with selections" src="images/GridwithSelections.png"/>
                        <h2>Curate.</h2>
                        </Col>
                        <Col lg={4} md={4} sm={12} >
                        <img className="icon" alt="puzzle pieces" src="images/PuzzlePieces.png"/>
                        <h2>Connect.</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="centered">
                            <h1>Product Search for IT Resellers</h1>
                            <p>Adaptive Catalog is the solution to all your product search frustrations. It's the one stop destination for MSPs to find centralized distributor data, customizable search filters, and convenient CRM integrations!</p>
                        </Col>
                    </Row>

                </div>

                <div className="section">
                    <Row>
                    <Col lg={12} md={12} sm={12} className="centered">
                        <h2>Upcoming Conferences:</h2>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={12} md={12} sm={12} className="centered">
                        <a href="/conferences/ITNConnect2023"><img style={{maxWidth: '100%'}} alt="IT Nation Connect 2023" src="images/ITNC23-Banner.png"/></a>
                    </Col>
                    </Row>
                    

                </div>
                
                <div id="videoWrap">
                    <Row>
                        <Col  lg={12} md={12} sm={12} id="demoVideo" className="centered">
                        <div className="embed-container"> 
                            <iframe height="720" src="https://www.youtube.com/embed/Ys2j0lAnWoQ" title="Adaptive Catalog" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <br/>
                        <a href="/#contactForm" className="btn btn-success">Schedule a demo</a>
                        </Col>
                    </Row>
                </div>
               
                <div>
                    <div className='section'>
                        <h2 className="centered">Key Features</h2>
                        <div>
                        <Feature videoWebm="videos/ACAvailabilityVideo.webm" videoMp4="videos/ACAvailabilityVideo.mp4" icon="/images/MapPin.png" iconAlt="map pin icon" title="Real-time Availability" description="See pricing and availability down to the warehouse level.  All your distributors for the products you need show up in one pane of glass." />
                        <Feature rightAlign videoWebm="videos/AdvancedSearchFilterVideo.webm" videoMp4="videos/AdvancedSearchFilterVideo.mp4" icon="/images/Funnel.png" iconAlt="funnel icon" title="Advanced Search Filtering" description="Filter on in stock up front so you avoid pages of discontinued and out of stock products.  Specify must have, nice to have, and excluded criteria for everything from amount of memory to country of origin." />
                        <Feature videoWebm="videos/RecommendedProductsVideo.webm" videoMp4="videos/RecommendedProductsVideo.mp4"icon="/images/Recommended.png" iconAlt="checkmark icon" title="Recommended Items" description="Recommended items appear right in the workspace so that salespeople can easily upsell and add accessories." />
                        </div>
                    </div>
                    <div className='section'>
                        <h2 className="centered">Integrations</h2>
                        <Integration image="images/CWPunchout.png" icon="images/ConnectWiseLogo.png" iconAlt="ConnectWise logo" title="ConnectWise CPQ" description="We are seamlessly integrated with CW CPQ. Punchout search allows you to dive into Adaptive Catalog directly from CW with the click of a button, no additional sign-in required! Then update opportunities right from Adaptive Catalog once you've found everything you need." />
                        <Integration rightAlign images={["images/KaseyaExport.png", "images/KaseyaPunchout.png"]} icon="images/KaseyaBMSlogo.gif" iconAlt="Kaseya BMS logo" title="Kaseya BMS" description="Our Kaseya BMS integration allows you to launch Adaptive Catalog from a quote in BMS. Find all the products you want to sell from one convenient location then export them into the BMS quote including the pricing and descriptions." />
                    </div>
                    <div className='section'>
                        <h2 className="centered">How We're Different</h2>
                        <p>You may already have a quoting solution that "shows" real time pricing. See the Adaptive Catalog difference in this side by side comparison:</p>
                        <div className="embed-container">
                            <iframe height="720" src="https://www.youtube.com/embed/wVN_b5OvF1I" title="Adaptive Catalog vs legacy search" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className='section partnerLogos'>
                        <h2 className="centered" >Our Partners</h2>
                        <Row>
                            <Col lg={4} md={4} sm={6} className="centered">
                                <img src="images/DandHlogo.png" alt="D and H logo"/>
                                <img src="images/Ingramlogo.png" alt="Ingram Micro Logo"/>
                            </Col>
                            <Col lg={4} md={4} sm={6} className="centered">
                                <img src="images/TDsynnexlogo.png" alt="TD Synnex Logo"/>
                                <img src="images/Pax8logo.png" alt="Pax8 logo"/>
                            </Col>
                            <Col lg={4} md={4} sm={6} className="centered">
                                <img src="images/ConnectWiseLogo.png" alt="ConnectWise Logo"/>
                                <img src="images/KaseyaBMSlogo.gif" alt="Kaseya BMS logo"/>
                            </Col>
                        </Row>
                    </div>
                    {/* <Row>
                        <Col className="section" lg={6} md={6} sm={12}>
                            <h2>Key Features</h2>
                        </Col>
                        <Col  className="section" lg={6} md={6} sm={12}>
                            <h2>Integrations</h2>
                        </Col>
                    </Row> */}
                </div>

                {/* <Row>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/CRM-ERPIntegrations">
                            <WalkThrough icon="/images/CRM-ERPSketch.png" iconAlt="CRM-ERP icon" title="CRM/ERP INTEGRATIONS" description="Synchronize all of your product data across multiple platforms." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/SearchCustomization">
                            <WalkThrough icon="/images/searchCustomizationSketch.png" iconAlt="search customization icon" title="SEARCH CUSTOMIZATION" description="Find products that match all of your criteria with custom search features." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/RealTimeAvailability">
                            <WalkThrough icon="/images/realTimeSketch.png" iconAlt="real time availability icon" title="REAL TIME AVAILABILITY" description="See pricing and availability in real time from all of your vendors." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/ECommerce">
                            <WalkThrough icon="/images/eCommerceSketch.png" iconAlt="eCommerce icon" title="E-COMMERCE" description="Manage your e-commerce website with ease from a central location." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/BidAnalysis">
                            <WalkThrough icon="/images/bidAnalysisSketch.png" iconAlt="bid analysis icon" title="BID ANALYSIS" description="Analyze complex RFPs to ensure your bids are a perfect fit." />
                        </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <a href="/Features/Catalogs">
                            <WalkThrough icon="/images/catalogsSketch.png" iconAlt="catalog icon" title="CATALOGS" description="Create personalized catalogs to keep track of all of your products in one place." />
                        </a>
                    </Col>
                </Row>
                <Row className="divider">
                </Row> 
                <Row>
                    <Col lg={4} md={4} sm={12}>
                        <Role icon="/images/salesPeopleIcon.png" iconAlt="salespeople icon" title="Salespeople" description="Industry leading search features help salespeople find products faster and instantly see current availability." />
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <Role icon="/images/procurementIcon.png" iconAlt="procurememt icon" title="Procurement" description="Delight your procurement team with consolidated product sources in one seamless interface." />
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <Role icon="/images/salesEngineersIcon.png" iconAlt="sales engineer icon" title="Sales Engineers" description="Sales engineers will appreciate viewing product specifications for all products in one easy place and filtering to search for specific technical requirements." />
                    </Col>
                </Row>  */}
            
                
                <Container>
                    <Row>
                        {/* <Col lg={6} md={12} sm={12} className='centered'>
                            <h3>What we do</h3>
                            <Jumbotron className='left'>
                                Adaptive Catalog provides you with a personalized catalog, where you can search across all your distributors, internal products, and service providers on one easy to use screen. Valuable product information is right at your fingertips, including part numbers, pictures, videos, and community recommendations. Your sales people will be able to create configurations and recommendations so that they can spend less time sourcing and more time selling!
                        </Jumbotron>
                        </Col> */}
                        <Col id="contactForm" lg={12} md={12} sm={12}>
                            <h3 className='centered'>Schedule a Demo</h3>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}