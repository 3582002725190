import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container
} from 'reactstrap';

const NavBar = (props: any) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar fixed='top' color="light" light expand="md">
                <Container>
                    <NavbarBrand href="/"><img id="navLogo" src="/images/mainLogo.png" alt="Home" /></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto mainNav"  navbar>
                            {/* <NavItem>
                                <NavLink
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                    href="/#demoVideo"
                                >
                                        Demo
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink href="/Videos/">Videos</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/AboutUs/">About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/executiveTeam/">Executive Team</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/pricing/">Pricing</NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                    href="/#contactForm"
                                >
                                        Contact
                                </NavLink>
                            </NavItem>
                        </Nav>

                    </Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavBar;