import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

interface ITNConnect23Props {

}

export const ITNConnect2023: React.FC<ITNConnect23Props> = (props) => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: rgb(187, 187, 187); }'}</style>
            </Helmet>
            <Container className='body-content'>

                <Container className="conferenceBody">
                    <Row className="ITN2023Banner centered">
                        <Col className="centered mx-auto" lg={4} md={6} sm={12} >
                            <img src="/images/ITNation-White.png" className="logoStyle" alt='IT Nation Connect'/> 
                            <h4>Nov 8-10th in Orlando</h4>
                        </Col>
                    </Row>
                    <Row className="greenBG">
                        <Col lg={6} md={12} sm={12} className='centered mx-auto' >
                            <div className="whiteBox centered">
                                <img src="/images/Adaptive-Catalog-White.png" className="logoStyle" alt='Adaptive Catalog'/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="greenBG">
                        <Col lg={12} md={12} sm={12} >
                            <div className="whiteBox centered">
                                <h4>25% off for 6 months <span style={{fontWeight: 'normal', fontSize: '80%', fontStyle:'italic'}}>and</span> 3 hours of implementation FREE - <span style={{fontSize: '120%', fontStyle:'italic'}}>$500 value!</span></h4><a href="/#contactForm" className="btn btn-contrast">SCHEDULE A DEMO</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className="ITN2023BannerBottom">
                        <Col lg={8} md={12} sm={12} className="ITNCDetails centered mx-auto">
                            <div>
                                <h2>Adaptive Catalog will be at booth #404</h2>
                                <p>Come pay us a visit to learn more about:</p>
                                <ul className="left">
                                    <li>Fast, reliable search for hardware, software, and cloud products in ConnectWise Sell</li>
                                    <li>All your distributor pricing and availability in one pane of glass</li>
                                    <li>Recommendations and configurations to match your customer requests</li>
                                    <li>Creating products and opportunities automatically in ConnectWise Manage</li>
                                </ul>
                                <p>We can't wait to see you there!</p>
                                 {/* <a href="/#contactForm" className="btn btn-success">Schedule a demo</a> */}
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}