import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const browserHistory = createBrowserHistory({ basename: '' });

const reCaptchaKey = '6LecQqAUAAAAABk2a6aqqS1mnUJl_96unPEVdWqu';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '30f41378-7e45-44b7-b34e-0a707dbbba2d',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

ReactGA.initialize('UA-161437441-1');

ReactDOM.render(<GoogleReCaptchaProvider
    reCaptchaKey={reCaptchaKey}>
        <App />
    </GoogleReCaptchaProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
