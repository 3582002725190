import React, { StatelessComponent } from 'react';
import { UncontrolledCarousel } from 'reactstrap';

interface IIntegrationProps {
    icon: string;
    iconAlt?: string;
    title: string;
    description: string;
    image?: string;
    images?: string[];
    rightAlign?: boolean;
}

export const Integration: React.FC<IIntegrationProps> = (props) => {
    return (
        <div className="feature">
            <div className={`halfSection ${props.rightAlign ? ' float-right' : ''}`}>
                <img className="icon" src={props.icon} alt={props.iconAlt} />
                <h3 className="titleLink float-left">{props.title}</h3>
                <p className="float-left">{props.description}</p>
            </div>
            <div className={`halfSection ${props.rightAlign ? ' float-right' : ''}`}>
                {props.image && <img width="100%" src={props.image} />}
                {props.images && <UncontrolledCarousel indicators={false} items={props.images.map(i =>  ({ src: i, caption: '' }))} />}
            </div>
            <div className='clearFix'/>
        </div>
    )
}