import React, { StatelessComponent } from 'react';
import {  Row, Col } from 'reactstrap';

interface IBioProps {
    bioPic: string;
    bioPicAlt?: string;
    name: string;
    jobTitle: string;
    bioText: string;
}

export const Bio: StatelessComponent<IBioProps> = (props) => {
    return (
        <Row className='bios'>
            <Col className='bios-col' lg={4} md={12} sm={12}>
                <img src={props.bioPic} alt={props.bioPicAlt}/>
            </Col>
            <Col className='bios-content' lg={9} md={12} sm={12}>
                <h2>{props.name}</h2>
                <h4>{props.jobTitle}</h4>
                <p>{props.bioText}</p>
            </Col>
        </Row>
        
    )
}