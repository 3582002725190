import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

interface IChannelPartnersProps {

}

export const ChannelPartnersExpo2021: StatelessComponent<IChannelPartnersProps> = (props) => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: rgb(187, 187, 187); }'}</style>
            </Helmet>
            <Container className='body-content'>

                <Container className="conferenceBody">
                    <Row className="blueGradient centered">
                        <Col className="centered mx-auto" lg={4} md={6} sm={12} >
                            <img src="/images/channelPartnersExpoLogo.svg" className="logoStyle" alt='Channel Partners'/> 
                            <h4>Nov 1-4th in Las Vegas</h4>
                        </Col>
                    </Row>
                    <Row className="whiteBG">
                        <Col lg={6} md={12} sm={12} className='centered mx-auto' >
                            <div className="whiteBox centered">
                                <img src="/images/mainLogo.png" className="logoStyle" alt='Adaptive Catalog'/>
                                <h2>Adaptive Catalog will be at booth #1756</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="conferenceBG">
                        <Col lg={6} md={12} sm={12} className='overlayGradientBox centered mx-auto'>
                            <div>
                                <p>Come pay us a visit to learn how you can:</p>
                                <ul className="left">
                                    <li>Improve your response times to customer RFPs</li>
                                    <li>Quickly match product specifications to bid requests</li>
                                    <li>See pricing and availability from distributors</li>
                                    <li>Create products and opportunities automatically in ConnectWise Manage</li>
                                </ul>
                                <p>We can't wait to see you there!</p>
                                <a href="/#contactForm" className="btn btn-success">Schedule a demo</a>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}