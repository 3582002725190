import React, { StatelessComponent } from 'react';

interface IFooterProps {

}

export const Footer: StatelessComponent<IFooterProps> = (props) => {
    return (
        <>
            <hr />
            <footer className="fine-print centered">
                &copy;{new Date().getFullYear()} - Adaptive Catalog
            </footer>
        </>
    )
}