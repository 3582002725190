import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { ContactForm } from '../../Components/ContactForm/ContactForm';

interface IBrochure2021Props {
}

export const Brochure2021: React.FC<IBrochure2021Props> = () => {
    return (
        <>
            <Container className='body-content'>
                <h1 className='centered'>Thank you for visiting our booth!</h1>
                <Row className="divider" style={{marginTop:'1em'}} />
                <Col lg={12} md={12} sm={12} id="demoVideo" className="centered">
                    <div className="embed-container">
                        <iframe title="Demo Video" src="https://www.youtube.com/embed/ip7R6p5dtpY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </Col>
                <Row className="divider" />
                <Container>
                    <Row>
                        <Col lg={6} md={12} sm={12} className='centered'>
                            <h3>What we do</h3>
                            <Container className='left'>
                                Adaptive Catalog provides you with a personalized catalog, where you can search across all your distributors, internal products, and service providers on one easy to use screen. Valuable product information is right at your fingertips, including part numbers, pictures, videos, and community recommendations. Your sales people will be able to create configurations and recommendations so that they can spend less time sourcing and more time selling!
                            </Container>
                        </Col>
                        <Col id="contactForm" lg={6} md={12} sm={12}>
                            <h3 className='centered'>Schedule a Demo</h3>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}