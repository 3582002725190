import React, { StatelessComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Bio } from '../Components/Bio/Bio';
import { ContactCard } from '../Components/ContactCard/ContactCard';

interface IContactCardProps {

}

export const ssteck: StatelessComponent<IContactCardProps> = (props) => {
    return (
        <>
            <Container className='body-content'>

                <Container>
                    
                    <ContactCard
                        bioPic="/images/stephenSteckBioPic.jpg"
                        bioPicAlt="Stephen Steck"
                        name="Stephen Steck"
                        jobTitle="Chief Technology Officer"
                        email="ssteck@adaptivecatalog.com"
                        phoneNumber='206.486.5008'
                        bioText={''}
                        vcard="/files/StephenSteck.vcf"
                    />

                </Container>
            </Container>
        </>
    );
}