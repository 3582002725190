import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ContactFormDAL } from '../../DAL/ContactFormDAL';

interface IContactFormProps {
    googleReCaptchaProps?: any;
    source?: string;
}

interface IContactFormState {
    reCaptchaToken?: string;
    name?: string;
    email?: string;
    message?: string;
    submitting: boolean;
    submitted: boolean;
    error?: string;
}

class ContactFormComponent extends Component<IContactFormProps, IContactFormState> {
    constructor(props: IContactFormProps) {
        super(props);
        this.state = {
            submitted: false,
            submitting: false,
        }
        this.Submit = this.Submit.bind(this);
    }

    public render() {
        const form = <Form>
            <FormGroup>
                <Label for='name'>Name *</Label>
                <Input
                    type='text'
                    name='name'
                    id='name'
                    placeholder='Please enter your name *'
                    disabled={this.state.submitting}
                    required={true}
                    onChange={(event) => {
                        this.setState({
                            name: event.target.value,
                        })
                    }}
                    />
            </FormGroup>
            <FormGroup>
                <Label for='email'>Email *</Label>
                <Input
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Please enter your email *'
                    disabled={this.state.submitting}
                    required={true}
                    onChange={(event) => {
                        this.setState({
                            email: event.target.value,
                        });
                    }}
                    />
            </FormGroup>
            <FormGroup>
                <Label for='message'>Message *</Label>
                <Input
                    type='textarea'
                    name='message'
                    id='message'
                    placeholder='Message.'
                    disabled={this.state.submitting}
                    required={true}
                    rows={4}
                    onChange={(event) => {
                        this.setState({
                            message: event.target.value,
                        });
                    }}
                    />
            </FormGroup>
            <div className='centered'>
                <Button
                    className='btn-send'
                    color='light'
                    disabled={this.state.submitting}
                    onClick={() => {
                        this.Submit();
                    }}
                    >
                    {this.state.submitting ? 'Submitting' : 'Contact Us'}
                </Button>
            </div>
        </Form>;
        const thanks = <h2 className="thankYouMessage">Thank you for reaching out!</h2>;
        return this.state.submitted ? thanks : form;
    }

    private async Submit() {
        this.setState({
            submitting: true,
        });
        const dal = new ContactFormDAL();
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');
        await dal.Run({
            name: this.state.name,
            email: this.state.email,
            message: 
                (this.props.source ? `SOURCE: ${this.props.source} ` : '') 
                + this.state.message,
            token: token,
        });
        this.setState({
            submitting: false,
            submitted: true,
        });
    }
}

export const ContactForm = withGoogleReCaptcha(ContactFormComponent);