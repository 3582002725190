import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NavBar from './Components/Nav/NavBar';
import { CookieConsentBar } from './Components/Messaging/CookieConsentBar';
import { Footer } from './Components/Footer/Footer';
import ACRouter from './ACRouter';

const App: React.FC = () => {
  return (
    <div className="App">
      <NavBar />
      <CookieConsentBar />
      <ACRouter />
      <Footer />
    </div>
  );
}

export default App;
