import React, { FunctionComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

interface KaseyaConnect23Props {

}

export const KaseyaConnect23: FunctionComponent<KaseyaConnect23Props> = (props) => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: rgb(187, 187, 187); }'}</style>
            </Helmet>
            <Container className='body-content'>

                <Container className="conferenceBody">
                    <Row className="conferenceBGKaseyaConnect23Top centered">
                        <Col className="centered mx-auto" lg={4} md={6} sm={12} >
                            <img src="/images/KaseyaConnectLogo.png" className="logoStyle" alt='Kaseya Connect 2023'/> 
                            <h4>April 24-27th in Las Vegas</h4>
                        </Col>
                    </Row>
                    <Row className="whiteBG">
                        <Col lg={6} md={12} sm={12} className='centered mx-auto' >
                            <div className="whiteBox centered">
                                <img src="/images/mainLogo.png" className="logoStyle" alt='Adaptive Catalog'/>
                                <h2>We'll be at Kaseya Connect 2023!</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="conferenceBGKaseyaConnect23">
                        <Col lg={8} md={12} sm={12} className="ITNCDetails centered mx-auto">
                            <div>
                                <p><h4>Your one stop shop for product search and procurement</h4></p>
                                <ul className="left">
                                    <li>Find the hardware, software, and cloud services you need to quote quickly</li>
                                    <li>Match configurations and manufacturer recommendations to customer needs</li>
                                    <li>See pricing and availability from all your distributors</li>
                                    <li>Update Quotes and create products automatically in Kaseya BMS</li>
                                </ul>
                                <p>We can't wait to see you there!</p>
                                <a href="/#contactForm" className="btn btn-success">Schedule a demo</a>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}