import React, { StatelessComponent } from 'react';
import { Row, Col } from 'reactstrap';

interface IDetailBlockProps {
    detailImage: string;
    detailImageAlt: string;
    detailTitle: string;
    detailText: string;
    imageRight?: boolean;
}

export const DetailBlock: StatelessComponent<IDetailBlockProps> = (props) => {
    const image = (
        <Col className={`detail-col ${props.imageRight ? 'order-md-12': 'order-md-1'}`} lg={6} md={6} sm={12}>
            <img src={props.detailImage} alt={props.detailImageAlt} />
        </Col>
    )
    const description = (
        <Col className={`detail-content ${props.imageRight ? 'order-md-1' : 'order-md-12'}`} lg={6} md={6} sm={12}>
            <h4>{props.detailTitle}</h4>
            <p>{props.detailText}</p>
        </Col>
    )
    return (
        <Row className='detail'>
            {description}
            {image}
        </Row>
    )
}