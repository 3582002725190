import React, { StatelessComponent } from 'react';

interface IVideoProps {
    videoLink: string;
    videoTitle: string;
    videoDescription: string;
}

export const Video: StatelessComponent<IVideoProps> = (props) => {

    return (
        <div className="video">
            {/* <CardImg top width="100%" src={props.videoLink} /> */}
            <div className="video-embed-container"> 
                <iframe  title={props.videoTitle} src={props.videoLink} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="videoDetails">
                <h6 className="titleLink">{props.videoTitle}</h6>
                <p>{props.videoDescription}</p>
            </div>
        </div>

    )
}