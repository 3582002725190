import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

interface ITNSecure2023Props {

}

export const ITNSecure2023: React.FC<ITNSecure2023Props> = (props) => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: rgb(187, 187, 187); }'}</style>
            </Helmet>
            <Container className='body-content'>

                <Container className="conferenceBody">
                    <Row className="conferenceBGITNSecure centered">
                        <Col className="centered mx-auto" lg={4} md={6} sm={12} >
                            <img src="/images/itnation-white.webp" className="logoStyle" alt='IT Nation'/> 
                            <h4>IT Nation Secure</h4>
                            <h4>June 5-7th in Orlando</h4>
                        </Col>
                    </Row>
                    <Row className="whiteBG">
                        <Col lg={6} md={12} sm={12} className='centered mx-auto' >
                            <div className="whiteBox centered">
                                <img src="/images/mainLogo.png" className="logoStyle" alt='Adaptive Catalog'/>
                                <h2>We'll be at booth #6</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="conferenceBGITNSecureBottom">
                        <Col lg={8} md={12} sm={12} className="ITNCDetails centered mx-auto">
                            <div>
                                <p>Come pay us a visit to learn more about:</p>
                                <ul className="left">
                                    <li>Fast, reliable search for hardware, software, and security products in ConnectWise CPQ</li>
                                    <li>All your distributor pricing and availability in one pane of glass</li>
                                    <li>Recommendations and configurations to match your customer requests</li>
                                    <li>Creating products and opportunities automatically in ConnectWise Manage</li>
                                </ul>
                                <p>We can't wait to see you there!</p>
                                <a href="/#contactForm" className="btn btn-success">Schedule a demo</a>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
}