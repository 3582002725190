import React, { FunctionComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

interface AsciiEdge23Props {

}

export const AsciiEdge23: FunctionComponent<AsciiEdge23Props> = (props) => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: rgb(187, 187, 187); }'}</style>
            </Helmet>
            <Container className='body-content'>

                <Container className="conferenceBody">
                    <Row className="conferenceBGAsciiEdge23Top centered">
                        <Col className="centered mx-auto" lg={4} md={6} sm={12} >
                            <img src="/images/AsciiEdgeLogo.png" className="logoStyle" alt='ASCII Edge 2023'/> 
                            <h4>April 19-20th in Dallas, TX</h4>
                        </Col>
                    </Row>
                    <Row className="whiteBG">
                        <Col lg={6} md={12} sm={12} className='centered mx-auto' >
                            <div className="whiteBox centered">
                                <img src="/images/mainLogo.png" className="logoStyle" alt='Adaptive Catalog'/>
                                <h2>We'll be at ASCII Edge 2023!</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="conferenceBGAsciiEdge23">
                        <Col lg={8} md={12} sm={12} className="ITNCDetails centered mx-auto">
                            <div>
                                <p><h4>Your one stop shop for product search and procurement</h4></p>
                                <ul className="left">
                                    <li>Find the hardware, software, and cloud services you need to quote quickly</li>
                                    <li>Match configurations and manufacturer recommendations to customer needs</li>
                                    <li>See pricing and availability from all your distributors</li>
                                    <li>Enjoy seamless integrations with many popular CRM systems, including ConnectWise Manage and Kaseya BMS</li>
                                </ul>
                                <p>We can't wait to see you there!</p>
                                <a href="/#contactForm" className="btn btn-success">Schedule a demo</a>
                            </div>
                            
                        </Col>
                        
                    </Row>
                    <div className="PinktoPurpleGradient row"></div>
                </Container>
            </Container>
        </>
    );
}